import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import {
  Book,
  CalendarRange,
  CodeSquare,
  Folder,
  JournalCode,
  Kanban,
  Pen,
} from "react-bootstrap-icons";
import { useLoaderData } from "react-router-dom";
import LaTeX from "../components/LaTeX";

export default function Gettingstarted() {
  const project = useLoaderData();

  return (
    <Container fluid="xxl">
      <h1 className="mb-4">Ako začať?</h1>

      <div className="lead font-weight-normal mb-4">
        <p>
          <strong>
            <Folder /> Skupina
          </strong>{" "}
          v GitLabe je pripravená pre vašu{" "}
          <strong>
            <Book /> záverečnú prácu
          </strong>{" "}
          a všetky{" "}
          <strong>
            <CodeSquare /> súvisiace projekty
          </strong>
          .
        </p>
      </div>

      <Row md={2} className="g-4">
        <Col sm={6}>
          <Card className="h-100">
            <Card.Body>
              <Card.Title>
                <CalendarRange /> Pozrite si míľniky
              </Card.Title>
              <Card.Text>
                Míľniky definujú termíny a požiadavky, ktoré musíte splniť počas
                práce na svojom projekte.
              </Card.Text>
              <Card.Link href={project.thesis_url + "/-/milestones"}>
                Míľniky
              </Card.Link>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={6}>
          <Card className="h-100">
            <Card.Body>
              <Card.Title>
                <Kanban /> Plánujte úlohy
              </Card.Title>
              <Card.Text>
                Použite GitLab aj na plánovanie úloh. Stanovujte si termíny
                alebo použite nástenku Kanban na organizáciu svojej práce.
              </Card.Text>
              <Card.Link href={project.thesis_url + "/-/issues"}>
                Úlohy
              </Card.Link>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={6}>
          <Card className="h-100">
            <Card.Body>
              <Card.Title>
                <JournalCode /> Vytvorte ďalšie projekty
              </Card.Title>
              <Card.Text>
                Vytvorte projekty pre zdrojový kód v rovnakej skupine ako text
                vašej práce. Všetko, čo súvisí s vašou prácou, bude na jednom
                mieste.
              </Card.Text>
              <Card.Link href={project.gitlab_url}>Skupina v GitLabe</Card.Link>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={6}>
          <Card className="h-100">
            <Card.Body>
              <Card.Title>
                <Pen /> Píšte vašu prácu
              </Card.Title>
              <Card.Text>
                {project.format === "latex" ? (
                  <>
                    Na písanie textu práce a definovanie jej štruktúry použite{" "}
                    <LaTeX />. Ukladajte zmeny v systéme Git kvôli zálohovaniu a
                    sledovaniu histórie.
                  </>
                ) : (
                  <>
                    Šablónu pre text práce nájdete vo vašom repozitári v
                    GitLabe. Ukladajte zmeny v systéme Git kvôli zálohovaniu a
                    sledovaniu histórie. Môžete pridávať aj pomocné súbory,{" "}
                    <strong>
                      názov súboru s prácou však musí zostať{" "}
                      <code>thesis.docx</code>
                    </strong>
                    .
                  </>
                )}
              </Card.Text>
              <Card.Link href={project.thesis_url}>Text práce</Card.Link>
            </Card.Body>
          </Card>
        </Col>

        {project.format === "latex" && (
          <Col sm={12}>
            <Card className="h-100" border="info">
              <Card.Body>
                <Card.Title>
                  <Book /> Neovládate <LaTeX />?
                </Card.Title>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <a
                      href={`${project.thesis_url}/-/blob/master/readme.md?ref_type=heads`}>
                      Súbor README
                    </a>{" "}
                    – ako rozbehať <LaTeX /> na vašom počítači pomocou Dockeru
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <a href="https://tobi.oetiker.ch/lshort/lshort.pdf">
                      The Not So Short Introduction to <LaTeX /> 2ε
                    </a>{" "}
                    – skvelá a krátka kniha
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <a href="https://magazin.kpi.fei.tuke.sk/2020/09/zaklady-prace-s-latexom/">
                      Základy práce s LaTeXom
                    </a>{" "}
                    v Magazíne KPI
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
}
