import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { PersonCircle } from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";
import { useRef } from "react";
import { getCookie } from "../functions/getCookie";

export default function Header({ department, user, teamId }) {
  const logoutFormRef = useRef(null);

  const handleLogout = () => {
    if (logoutFormRef.current) {
      logoutFormRef.current.submit();
    }
  };

  function LogoutForm() {
    return (
      <form
        ref={logoutFormRef}
        action="/auth/logout/"
        method="POST"
        style={{ display: "none" }}>
        <input
          type="hidden"
          name="csrfmiddlewaretoken"
          value={getCookie("csrftoken")}
        />
      </form>
    );
  }

  return (
    <Navbar className="mb-3" bg="light" data-bs-theme="light" expand="lg">
      <Container fluid="xxl">
        <LinkContainer to={`/`}>
          <Navbar.Brand>
            Záverečné práce{department && ` na ${department.abbr}`}
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="me-auto">
            {user && user.is_teacher && (
              <LinkContainer to={`/archive`}>
                <Nav.Link active={false}>Archív</Nav.Link>
              </LinkContainer>
            )}
            <LinkContainer to={`/milestones`}>
              <Nav.Link active={false}>Míľniky</Nav.Link>
            </LinkContainer>
            <Nav.Link href="/instructions" target="_blank">
              Pokyny
            </Nav.Link>
            {user && (
              <LinkContainer to={`/workweek`}>
                <Nav.Link active={false}>Workweek</Nav.Link>
              </LinkContainer>
            )}
            {user && user.is_teacher && teamId && (
              <LinkContainer to={`/workweek/${teamId}/settings`}>
                <Nav.Link active={false}>Spravovať tím</Nav.Link>
              </LinkContainer>
            )}
            {user && user.is_staff && (
              <LinkContainer to={`/projects/all`}>
                <Nav.Link active={false}>Všetky projekty</Nav.Link>
              </LinkContainer>
            )}
          </Nav>
          {user && (
            <NavDropdown
              align="end"
              title={
                <>
                  <PersonCircle size={20} className="align-text-bottom" />
                  &nbsp;
                  {user.full_name}
                </>
              }>
              <NavDropdown.Item onClick={handleLogout}>
                Odhlásiť sa
              </NavDropdown.Item>
            </NavDropdown>
          )}
        </Navbar.Collapse>
      </Container>
      <LogoutForm />
    </Navbar>
  );
}
