import "../assets/css/latex.css";

export default function LaTeX() {
  return (
    <span className="latex">
      L<span className="latex-sup">a</span>T<span className="latex-sub">e</span>
      X
    </span>
  );
}
