import { Alert, Container } from "react-bootstrap";

export async function loadCreateWorkweek() {
  const response = await fetch(`/api/milestones/`);
  if (!response.ok) throw response;
  return response.json();
}

export function CreateWorkweek() {
  return (
    <Container fluid="xxl">
      <Alert variant="warning">Vytváranie tímov je dočasne pozastavené.</Alert>
    </Container>
  );
}
